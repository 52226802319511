
import { defineComponent, ref } from "vue";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "two-factor-auth-modal",
  components: {
    ErrorMessage,
    Field,
    Form
  },
  setup() {
    const value = ref("apps");

    const state = ref("");

    const submitAuthCodeButtonRef = ref<null | HTMLButtonElement>(null);
    const submitMobileButtonRef = ref<null | HTMLButtonElement>(null);

    const schema1 = Yup.object().shape({
      mobile: Yup.string()
        .required()
        .label("Mobile")
    });

    const schema2 = Yup.object().shape({
      code: Yup.string()
        .required()
        .label("Code")
    });

    const submitAuthCodeForm = () => {
      if (submitAuthCodeButtonRef.value) {
        // Activate indicator
        submitAuthCodeButtonRef.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitAuthCodeButtonRef.value?.removeAttribute("data-kt-indicator");

          Swal.fire({
            text: "Form has been successfully submitted!",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary"
            }
          }).then(() => {
            state.value = "";
          });
        }, 2000);
      }
    };

    const submitMobileForm = () => {
      if (!submitMobileButtonRef.value) {
        return;
      }

      //Disable button
      submitMobileButtonRef.value.disabled = true;
      // Activate indicator
      submitMobileButtonRef.value.setAttribute("data-kt-indicator", "on");

      setTimeout(() => {
        if (submitMobileButtonRef.value) {
          submitMobileButtonRef.value.disabled = false;

          submitMobileButtonRef.value?.removeAttribute("data-kt-indicator");
        }

        Swal.fire({
          text: "Form has been successfully submitted!",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary"
          }
        }).then(() => {
          state.value = "";
        });
      }, 2000);
    };

    return {
      value,
      state,
      schema1,
      schema2,
      submitAuthCodeForm,
      submitMobileForm,
      submitAuthCodeButtonRef,
      submitMobileButtonRef
    };
  }
});
